<template>
  <div class="plan-page">
    <WsLogoHeader/>
    <v-sheet
        class=""
        color="white"
        light
        elevation="0"
    >
      <v-container class="mt-5 mb-10">
        <h3 class="plan-page__title">
          Your Warehousing &amp; Fulfillment Subscription Plan
        </h3>
        <h6 class="plan-page__note">No Credit Card Required</h6>
        <v-row class="d-flex flex-row align-stretch">
          <v-col md="6" sm="12" order-sm="1" order-md="2">
            <v-container class="py-0 px-3" fluid>
              <v-row class="d-flex justify-center">
                <v-card hover class="py-6 d-flex flex-column col-md-10 col-sm-12">
                  <v-card-title class="justify-content-center" :class="$vuetify.breakpoint.mobile ? 'py-0' : ''">
                    <h5 class="plan-page__form-title" :class="$vuetify.breakpoint.mobile ? 'text-sm-body-2' : 'text-md-h5'"> Start your 7-Day FREE trial </h5>
                  </v-card-title>
                  <v-form ref="form" class="contactFrom container d-flex flex-column" lazy-validation>
                    <v-text-field
                        label="Name"
                        v-model="name"
                        required
                        outlined
                        :rules="rules.nameRules"
                        :dense="$vuetify.breakpoint.mobile"
                    ></v-text-field>
                    <v-text-field
                        label="Email"
                        v-model="email"
                        required
                        outlined
                        :rules="rules.emailRules"
                        :dense="$vuetify.breakpoint.mobile"
                    ></v-text-field>
                    <v-text-field
                        label="Phone"
                        v-model="phone"
                        required
                        outlined
                        :rules="rules.phoneRules"
                        :dense="$vuetify.breakpoint.mobile"
                    ></v-text-field>

                    <v-checkbox v-model="status" required>
                      <template v-slot:label>
                        <div>
                          I agree to the
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <a
                                  target="_blank"
                                  href="/plans/terms-and-conditions"
                                  @click.stop
                                  v-on="on"
                              >
                                Terms and Conditions
                              </a>
                            </template>
                            Read Terms and Conditions
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn block color="primary" class="align-self-end" @click="submitForm" :x-large="!$vuetify.breakpoint.mobile" :loading="loading">
                      Submit
                    </v-btn>
                  </v-form>
                </v-card>
              </v-row>
            </v-container>
          </v-col>
          <v-col md="6" sm="12" order-sm="2" order-md="1" cols="12">
            <v-container class="p-0" fluid>
              <v-card>
                <img :src="invoicePicture" v-if="currPlan" height="100%" width="100%" :alt="`${this.currPlan.name} invoice`"/>
              </v-card>
            </v-container>
          </v-col>
        </v-row>

        <!-- WHATSAPP -->
        <!-- <ws-whatsapp-popup category="plan" :label="`Package ${this.currPlan.id} submit page`"/> -->
      </v-container>
    </v-sheet>
    <WsFooter />
  </div>
</template>
<script>
import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import {metadata} from "@/plugins/metadata";
import plansMixins from "@/mixins/plans.js";
import WsLogoHeader from "@/components/ws-logo-header";
import WsFooter from "@/components/ws-footer";
import WsWhatsappPopup from "@/components/ws-whatsapp-popup";
import {PlanRequest} from "@/integration/plan-form";
import {types, sub_types} from "@/services/westore/enums";

export default mixins(MyMixin, plansMixins).extend({
  name: "Plan",
  components: {WsLogoHeader, WsFooter, WsWhatsappPopup},
  metaInfo() {
    let title = this.title;
    return {
      title,
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        {charset: "utf-8"},
        {name: "robots", content: "follow, index"},
        {
          name: "description",
          content: metadata.Contact.description,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, user-scalable=no",
        },
      ],
    }
  },
  methods: {
    getSelectedPlan() {
      this.currPlan = this.plans.find(ele => {
        return ele.slug === this.$route.params.slug
      });
      if (!this.currPlan) {
        this.$router.push('/')
      }
    },
    submitForm() {
      if (!this.$refs.form.validate() || !this.status) {
        return;
      }
      this.loading = !this.loading;
      PlanRequest
          .send({
            type: types.Subscription,
            sub_type: sub_types[this.currPlan.name],
            name: this.name,
            email: this.email,
            phone: this.phone,
          })
          .then(({data: record}) => {
            // Clear
            this.name = '';
            this.email = '';
            this.phone = '';
            this.$refs.form.reset();

            this.logGAEvent(
                "plan",
                `Submit package ${this.currPlan.id} selected`,
                this.$route.name
            );
            this.$router.push({name: 'plan-thank-you', params: {package: this.currPlan.id}})
          })
          .catch((err) => {
            this.logGAException(err);
          })
          .finally(() => {
            this.loading = !this.loading;
          });
    },
  },
  created() {
    this.getSelectedPlan()
  },
  mounted() {
    this.trackMyPageView();
  },
  data() {
    return {
      currPlan: {},
      loading: false,
      name: "",
      email: "",
      phone: "",
      status: true,
      rules: {
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
              (v && v.length >= 3) || "Name must be at least 3 characters",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phoneRules: [
          (v) => !!v || "Phone is required",
          (v) =>
              /^[\d ]*$/.test(v) || "Phone must be valid (digits only)",
          (v) =>
              (v && v.replace(/\s/g, "").length >= 9) ||
              "Phone must be at least 9 characters",
        ],
      },
    }
  },
  computed: {
    title() {
      if (!this.currPlan) {
        return '';
      }

      return `${this.currPlan.name} Plan`;
    },
    invoicePicture() {
      if (!this.currPlan) {
        return '';
      }
      const images = require.context('@/assets/imgs/plan/', false, /\.png$/)
      return images('./' + this.currPlan.name.toLowerCase() + '-invoice.png');
    }
  }
});
</script>
<style lang="scss">
.plan-page {
  .contactFrom {
    input[type=text] {
      border: none;
    }
  }
}

</style>