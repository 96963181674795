




























































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";

export default mixins(MyMixin).extend({
  name: "About",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.About.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.About.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    country() {
      switch (this.$route.params.country) {
        case 'ae':
          return 'UAE';
        case 'kw':
          return 'KWT';
        default:
          return 'QAT';
      }
    }
  },
  methods: {
    showHideContactsCustom() {
      if (!this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `About Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    ourValues: [
      {
        number: "01",
        title: "Reducing our carbon footprint",
      },
      {
        number: "02",
        title: "Minimizing energy consumption",
      },
      {
        number: "03",
        title: "Increasing efficiency",
      },
      {
        number: "04",
        title: "Being compliant with standards and regulations",
      },
      {
        number: "05",
        title: "Minimizing waste",
      },
      {
        number: "06",
        title: "Minimizing recycling efforts",
      },
    ],
  }),
});
